
import Vue from 'vue';

export default Vue.extend({
  data(): { links: Array<String> } {
    return {
      links: [
        '/parkeren-rotterdam-airport/speed-parking',
        '/parkeren-schiphol/247parkeren',
        '/parcheggio-linate',
        '/parcheggio-aeroporto-palermo',
        '/parcheggio-aeroporto-firenze',
        '/parcheggio-aeroporto-torino',
        '/parcheggio-aeroporto-venezia',
        '/parcheggio-cagliari',
      ],
    };
  },
});
